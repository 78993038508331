














import {
  computed,
  defineComponent,
  PropType,
  useFetch
} from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import { useCtaPartial } from '@theme/composables';
import { LinkType } from '@theme/composables/useCtaPartial';

export default defineComponent({
  name: 'CtaPartial',
  components: {
    SfButton
  },
  props: {
    // This option controls the behavior and data that is used for the button
    linkType: {
      type: String as PropType<LinkType.PRODUCT_SKU | LinkType.CATEGORY_ID | LinkType.AMPLIENCE_ID |
        LinkType.EXTERNAL_URL>,
      default: LinkType.EXTERNAL_URL
    },
    title: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: ''
    },
    // Button type for styling, accepted values: Primary, Secondary
    type: {
      type: String as PropType<'Primary' | 'Secondary'>,
      default: 'Primary'
    },
    newWindow: {
      type: Boolean,
      default: false
    },
    noFollow: {
      type: Boolean,
      default: false
    },
    trackingQuery: {
      type: String,
      default: ''
    },
    classes: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const {
      ctaUrl,
      ctaExecRequest
    } = useCtaPartial(props.linkType, props.url);

    useFetch(async () => {
      await ctaExecRequest(props.linkType);
    });

    const formattedUrl = computed(() => {
      if (props.linkType === LinkType.EXTERNAL_URL) {
        return props.url;
      }

      return props.trackingQuery ? ctaUrl.value + props.trackingQuery : ctaUrl.value;
    });

    const buttonClasses = computed(() => [
      props.classes,
      props.type.toLowerCase(),
      'custom-button',
      'cta-label--default',
    ]);

    return {
      buttonClasses,
      ctaUrl,
      formattedUrl,
    }
  }
});
